import React, { useState } from 'react';

// Layout
import Layout from '../components/layout';
import PricingBlock from '../components/pricing_block';
import CTABlock from '../components/cta_block';
import FAQBlock from '../components/FAQ';

// Data
import testimonials from '../data/testimonials';
import FeaturesBlock from '../components/FeaturesBlock';

const PricingPage = () => {
  const [billingInterval, setBillingInterval] = useState('year');

  return (
    <Layout pageTitle="Adminmate — Pricing">
      <div>
        <div className="w-5/6 md:w-4/5 mx-auto">
          <div className="py-4 md:py-12 text-center w-4/5 mx-auto fade-in fade-in-first">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight">Upgrade your back-office within minutes</h1>
            <p className="text-xl pt-6 pb-6 text-gray-600">Whether you're a <span className="colored font-semibold">solo business owner</span>, a <span className="colored-green font-semibold">growing startup</span> or a <span className="text-black font-semibold">large company</span>, Adminmate is here to help you build high-performing admin panel dashboard for the right price. Pay for as little or as much usage as you need.</p>
          </div>

          {/* Pricing Block */}
          <PricingBlock interval={billingInterval} setBillingInterval={setBillingInterval} />

          {/* Logos Block */}
          {/* <LogosBlock /> */}

          {/* Start Testimonial */}
          <div className="text-center flex flex-col md:flex-row my-6 md:my-16 fade-in fade-in-fourth">
            <div className="md:w-1/2 flex flex-col my-4 lg:my-0 md:px-4">
              <blockquote className="italic text-gray-700 text-sm md:text-md lg:text-lg order-first">
                "{testimonials[0].content}"
              </blockquote>
              <div className="mt-3 flex justify-center content-center items-center order-last ">
                <img className="self-start loaded w-8 rounded-full" alt={testimonials[0].title} src={testimonials[0].thumbnail} />
                <span className="ml-2 font-bold text-gray-900 md:text-sm">{testimonials[0].title}</span>
              </div>
            </div>

            <div className="md:w-1/2 flex flex-col my-4 lg:my-0 md:px-4">
              <blockquote className="italic text-gray-700 text-sm md:text-md lg:text-lg  order-first">
              "{testimonials[3].content}"
              </blockquote>
              <div className="mt-3 flex justify-center content-center items-center order-last ">
                <img className="self-start loaded w-8 rounded-full" alt={testimonials[3].title} src={testimonials[3].thumbnail} />
                <span className="ml-2 font-bold text-gray-900 md:text-sm">{testimonials[3].title}</span>
              </div>
            </div>
          </div>

        </div>

        <div className="px-7 mx-auto max-w-7xl mt-14 md:mt-28">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight text-center mb-4">A comprehensive solution for dev & team</h2>
          <p className="text-lg pt-4 pb-6 text-gray-600 font-normal lg:leading-8 lg:w-3/6 mx-auto text-center mb-8">We’ve spent 2 years working on everything that a admin panel requires. Adminmate is a neverending story on which we will iterate.</p>
          <FeaturesBlock />
        </div>

        <FAQBlock />
        <CTABlock />
      </div>
    </Layout>
  )
};

export default PricingPage;